import axios, { AxiosInstance } from "axios";

class Spotify {
    private _client: AxiosInstance;
    private _token: string|null;
    private _baseUrl: string;
    private _ready = false;
    private clientID: string;
    private redirectUri: string;

    
    constructor() {
        this._token = localStorage.getItem('spotify_access_token') ?? null;
        this._baseUrl = 'https://api.spotify.com/v1/';
        this.clientID = 'a50675000e8e4c8ca95c3e8d753feb90';
        this.redirectUri = process.env.VUE_APP_BASE_URL + '/spotifyCallBack';
        if (this._token) {
            this._createClient();
            this._ready = true;
        }
    } 

    async search(query: string) {
        await this._waitUntilReady();
        return this._client.get('search', {
            params: {
                q: query,
                type: 'track',
                market: 'DK'
            }
        });
    }

    get token() {
        return this._token;
    }

    connect() {
        const authUrl = 'https://accounts.spotify.com/authorize';

        const codeVerifier = this.generateRandomString(128);

        this.generateCodeChallenge(codeVerifier).then((codeChallenge) => {
            let state = this.generateRandomString(16);
            let scope = 'user-read-private user-read-email';

            localStorage.setItem('code_verifier', codeVerifier);

            const args = new URLSearchParams({
                response_type: 'code',
                client_id: this.clientID,
                scope: scope,
                redirect_uri: this.redirectUri,
                state: state,
                code_challenge_method: 'S256',
                code_challenge: codeChallenge
            });

            window.location.href = authUrl + '?' + args;
        });

    }

    disconnect() {
        localStorage.removeItem('spotify_access_token');
        this._token = null;
        this._ready = false;
    }

    private async _waitUntilReady(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this._ready) {
                resolve();
            }
            setInterval(() => {
                if (this._ready) {
                    resolve();
                }
            }, 100);
        });
    }

    private _createClient() {
        this._client = axios.create({
            baseURL: this._baseUrl,
            headers: {
                "Content-type": "application/json",
                'Authorization': 'Bearer ' + this._token,
            },
        });
    }

    private generateRandomString(length: number) {
        let text = '';
        let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      
        for (let i = 0; i < length; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    private async generateCodeChallenge(codeVerifier: string) {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const digest = await window.crypto.subtle.digest('SHA-256', data);
      
        return this.base64encode(digest);
    }

    private base64encode(string: ArrayBuffer) {
        return btoa(String.fromCharCode(...new Uint8Array(string)))
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=+$/, '');
    }
    

    async getAuthToken(code: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const code_verifier = localStorage.getItem('code_verifier');
            let body = new URLSearchParams({
                grant_type: 'authorization_code',
                code: code,
                redirect_uri: this.redirectUri,
                client_id: this.clientID,
                code_verifier: String(code_verifier)
              });
            
            const response = fetch('https://accounts.spotify.com/api/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: body
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('HTTP status ' + response.status);
                }
                return response.json();
            })
            .then(data => {
                localStorage.setItem('spotify_access_token', data.access_token);
                this._token = data.access_token;
                this._createClient();
                this._ready = true;
                resolve();
            })
            .catch(error => {
                console.error('Error:', error);
            });


        });
    }
}

export const SpotifyService = new Spotify();