import { RouteLocationNormalized, Router } from 'vue-router';
import { directus } from "../services/directus";

export function registerGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized, from: any, next: any) => {
    if (to.meta.role) {
      try {
        const role:any = await directus.users.me.read({
          fields: ['role.*']
        });

        if (role.role?.name !== to.meta.role) {
          next("/");
          return;
        }
      } catch (error) {
        next("/");
        return;
      }
   }
   next();
  });
}