import { directus } from "@/services/directus";

class User {
    public _loginStatus: boolean;
    private _username: string;
    private _email: string;
    private _id: string;
    private _spotifyToken: string;
    private ready: boolean;
    private _isEditor: boolean;
    private _name: string;

    constructor() {
        this.ready = false;
        this._loginStatus = false;
        this._username = '';
        this._email = '';
        this._id = '';
        this._spotifyToken = '';
        this._name = '';
        this._isEditor = false;
    }

    async init(): Promise<void> {
        
        try {
            await directus.auth.refresh();
            this._getUserDetails();
        } catch(error) {
            this.ready = true;
            console.log('Not logged in');
        }
    }

    get isEditor() {
        return this._isEditor;
    }

    get name() {
        return this._name;
    }

    async isReady(): Promise<boolean> {
        return new Promise ((resolve, reject) => {
            if (this.ready) {
                resolve(true);
            }
            const readyInterval = setInterval(() => {
                if (this.ready) {
                    clearInterval(readyInterval);
                    resolve(true);
                }
            }, 100);
        });
    }

    async login(username: string, password: string): Promise<void> {
        return new Promise ((resolve, reject) => {
                directus.auth.login({
                    email: username,
                    password: password
                }
                
            ).then((response) => {
                this._getUserDetails()
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    }

    private async _getUserDetails(): Promise<void> {
        return new Promise ((resolve, reject) => {
            directus.users.me.read({
                fields: ['*', 'role.*']
            })
            .then((response: any) => {
                this._email = response.email ?? '';
                this._id = response.id ?? '';
                this._spotifyToken = response.spotifyToken ?? '';
                this._loginStatus = true;
                this._name = response.first_name ?? response.email;
                this._isEditor = (response.role?.name === 'Editor') ? true : false;
                resolve();
            })
            .catch((error) => {
                console.error("err", error);
                this._loginStatus = false;
                reject(error);
            })
            .finally(() => {
                this.ready = true;
            });
        });
    }

    async signup(email: string, password: string): Promise<void> {
        return new Promise ((resolve, reject) => {
            directus.items("directus_users").createOne({
                email: email,
                password: password,
                role: process.env.VUE_APP_USER_ROLE
            })
            .then(() => {
                this.login(email, password).then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
    
        });
        
    }

    async logout(): Promise<void> {
        return new Promise ((resolve, reject) => {
            directus.auth.logout().then(() => {
                this._loginStatus = false;
                this._username = '';
                this._email = '';
                this._id = '';
                this._isEditor = false;
                localStorage.removeItem('code_verifier');
                //localStorage.removeItem('spotify_access_token');
                resolve();
            });
        });
    }

    get loginStatus() {
        return this._loginStatus;
    }

    get username() {
        return this._username;
    }

    get email() {
        return this._email;
    }

    get id() {
        return this._id;
    }

    get spotifyToken() {
        return this._spotifyToken;
    }

    set spotifyToken(value: string) {
        this._spotifyToken = value;
        directus.auth.refresh()
        .then((token) => {
            directus.users.me.update({ spotifyToken: this._spotifyToken });
         })
        .catch((error) => { console.error(error) });
    }

}

export const UserService = new User();