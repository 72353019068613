
     import { defineComponent } from 'vue';
     import { directus } from "@/services/directus";

     export default defineComponent({
         components: {
         },
         data: () => ({
            categories: {} as Object,
            newCategory: '',
            errors: false,
            justAdded: '',
        }),
         mounted() {
            this.fetchCategories();
         },
         methods: {
            async fetchCategories() {
                const response = await directus.items("Category").readByQuery({
                    fields: ["*"],
                    sort: "category",
                } as any);

                this.categories = response.data ?? {};
            },

            async addCategory() {
                directus.items("Category").createOne({
                    category: this.newCategory,
                    status: "draft"
                } as any)
                .then((response: any) => {
                    this.errors = false;
                    this.justAdded = this.newCategory;
                    this.newCategory = '';
                    setTimeout(() => {
                        this.justAdded = '';
                    }, 2000);
                    this.fetchCategories();
                })
                .catch((error: any) => {
                    this.errors = error;
                });
            }
        },
     });

 