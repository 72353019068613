import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    musicQuiz: "Music Quiz",
    pageNotFound: "Page not found",
    goToHomePage: "Go to home page",
    notLoggedIn: "You can login to save your quizzes",
    WelcomeToMusicQuiz: "Welcome to the Music Quiz, the quiz where you don't need to know the artist or the song title to win!",
    searchForSongOnSpotify: "Search for a song on Spotify",
    ok: 'Ok',
    cancel: 'Cancel',
    search: 'Search',
    searchForSong: 'Search for a song',
    addSong: '+ song',
    addNewSong: 'Add new song',
    addCategory: '+ category',
    login: 'Log in',
    logout: 'Log out',
    signUp: 'Sign up',
    signUpForMusicQuiz: 'Sign up for Music Quiz',
    create: 'Create',
    emailAddress: 'Email address',
    password: 'Password',
    wellNeverShareYourEmail: "We'll never share your email with anyone else!",
    pleaseEnterValidEmailAddress: 'Please enter a valid email address',
    emailAlreadyExists: 'Email already exists',
    spotifyNotConnected: "Spotify not connected, you need to connect to spotify to add songs",
    connectToSpotify: 'Connect to Spotify',
    home: 'Home',
    
  },
  da: {
    musicQuiz: "Musik Quiz",
  },
};

const i18n = new createI18n({
  locale: "en",
  legacy: false,
  messages: messages,
});

export default i18n;