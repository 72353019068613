
     import { defineComponent } from 'vue';
     import { directus } from "@/services/directus";
     import SpotifySearch from '@/components/SpotifySearch.vue';
     import { SpotifyService } from '@/services/SpotifyService';

     export default defineComponent({
        components: {
            SpotifySearch
        },
        data: () => ({
            categories: {} as Object,
            songs: {} as Object,
            languages: {} as Object,
            showSpotifySearch: false,
            spotifyConnected: false,
            song: {
                name: '',
                spotify_id: '',
                category_id: '',
                status: 'draft',
                answer: '',
                image: '',
                artist: '',
                language_id: ''
            },
            errors: false,
            justAdded: '',
        }),
        mounted() {
            this.fetchCategories();
            this.fetchLanguages();
            this.spotifyConnected = SpotifyService.token ? true : false;
        },
        computed: {
            isDisabled() {
                let exists = false;
                for (const categoryIndex in this.categories) {
                    const category: any = this.categories[categoryIndex as keyof Object];
                    for (const songIndex in category['songs']) {
                        const song = category['songs'][songIndex as keyof Object];
                        if (song.name == this.song.name && song.answer.toLowerCase() == this.song.answer.toLowerCase() && song.category_id == this.song.category_id) {
                            return true;
                        }
                    }
                }

                return this.song.name == '' || this.song.answer == '' || this.song.category_id == '' || this.song.language_id == '';
            }
        },
        methods: {
            async fetchCategories() {
                const response = await directus.items("Category").readByQuery({
                    fields: ["*", "songs.*"],
                    sort: "category",
                } as any);


                this.categories = response.data ?? {};
            },

            async fetchLanguages() {
                const response = await directus.items("language").readByQuery({
                    fields: ["*"],
                    sort: "code",
                } as any);

                this.languages = response.data ?? {};
            },

            async addSong() {
                directus.items("songs").createOne(this.song)
                .then((response: any) => {
                    this.errors = false;
                    this.justAdded = this.song.name;
                    setTimeout(() => {
                        this.justAdded = '';
                    }, 2000);
                    this.song = {
                        name: '',
                        spotify_id: '',
                        category_id: '',
                        status: 'draft',
                        answer: '',
                        image: '',
                        artist: '',
                        language_id: ''
                    }
                    this.fetchCategories();
                })
                .catch((error: any) => {
                    this.errors = error;
                });
            },

            findSong() {
                this.showSpotifySearch = true;
            },

            songSelected(song: any) {
                this.song.name = song.name;
                this.song.spotify_id = song.id;
                this.song.image = song.album.images[2].url;
                this.song.artist = song.artists.map((artist: any) => artist.name).join(', ');
                this.showSpotifySearch = false;
            },

            closeSearch() {
                this.showSpotifySearch = false;
            },

            connectToSpotify() {
                SpotifyService.connect();
            },

            removeSpotifyLink() {
                SpotifyService.disconnect();
                window.location.reload();
            }
        },
     });

 