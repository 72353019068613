import { createRouter, createWebHistory } from "vue-router";
import { registerGuard } from './Guard';
import PageNotFound from "../modules/Errors/PageNotFound.vue";
import AddCategory from "../modules/Admin/AddCategory.vue";
import AddSong from "../modules/Admin/AddSong.vue";
import Main from "../modules/Main.vue";

const routes = [
    {
        path: "/",
        name: "landing_page",
        component: Main,
        meta: {
            title: "Home",
        }
    },
    {
        path: "/spotifyCallBack",
        name: "spotify_callback",
        component: Main,
        meta: {
            title: "Connect to Spotify",
        }
    },
    {
        path: "/admin/add_category",
        name: "add_category",
        component: AddCategory,
        meta: {
            role: "Editor",
            title: "Add category",
        }
    },
    {
        path: "/admin/add_song",
        name: "add_song",
        component: AddSong,
        meta: {
            role: "Editor",
            title: "Add Song",
        }
    },
    {
        path: "/:catchAll(.*)",
        component: PageNotFound,
        meta: {
            title: "Siden kunne ikke findes",
            showMenuButton: true,
        }
    }
]

const routerHistory = createWebHistory();
const router = createRouter({
  history: routerHistory,
  routes: routes,
});

registerGuard(router);

export default router;